import React from 'react';
import styled from 'styled-components';

import {
  Container,
  FlexWrapper,
  GridWrapper,
  H1,
  Image,
  PrimaryButton,
} from 'components';
import { blue, primary, text, tints, white } from 'styles/colors';
import { useQuery } from 'styles/breakpoints';

const PULSIO_INGREDIENTS = [
  {
    image: 'fenugreek_seed',
    name: 'Fenugreek seed',
    text:
      'For thousands of years, fenugreek has been used in alternative and Chinese medicine to treat skin conditions and many other diseases. Recently it has been found to have positive effects on managing blood sugar levels. Evidence supports fenugreek\'s role in blood sugar control and treating type 1 and 2 diabetes: in one study, people with type 1 diabetes took fenugreek seed powder at lunch and dinner. In 10 days, they experienced better sugar levels and reduced blood cholesterol levels.*',
  },
  {
    image: 'nattokinase',
    name: 'Nattokinase',
    text:
      'Nattokinase is a natural enzyme in natto, a Japanese soy-based food. The enzyme is produced during natto\'s fermentation process by a specific bacterium called Bacillus subtilis. It is widely used in Japan, and we all know that Japanese people are among the oldest in the world, and only 15% of deaths in Japan are caused by heart disease. In the US, it\'s a staggering 25%. What a difference! High natto consumption has been linked to the Japanese population\'s longer average lifespans and lower chronic disease rates. In particular, studies show that people with diets rich in natto have lower rates of death from heart diseases.*',
  },
  {
    image: 'vitamin_k2',
    name: 'Vitamin K2',
    text:
      'Vitamin K was discovered in 1929 as an essential nutrient for blood coagulation (blood clotting). This vitamin helps reduce the calcium buildup in your arteries. Calcium accumulation is a huge risk factor for developing heart disease. In one study spanning 7–10 years, people with the highest intake of vitamin K2 were 52% less likely to develop artery calcification and had a 57% lower risk of dying from heart disease.*',
  },
  {
    image: 'beetroot_extract',
    name: 'Beetroot extract',
    text:
      'Helps lower blood pressure. In addition, it assists in improving the strength of your heart, resulting in a cardiovascular system that\'s more resistant to possible diseases. It\'s also a unique source of betacyanin, an antioxidant important for a healthy heart. Betacyanin isn\'t the only reason beetroots can boost heart health. Rich in nitrates, studies suggest beetroot juice increases plasma nitrate and nitrite concentrations, supporting healthy blood pressure.*',
  },
  {
    image: 'coenzyme_q10',
    name: 'Coenzyme Q10',
    text:
      'CoQ10 may have significant benefits for people with cardiovascular disease, from reducing risk for repeat heart attacks and improving outcomes in patients with heart failure to lowering blood pressure and helping combat side effects of cholesterol-lowering statins.*',
  },
  {
    image: 'grapeseed',
    name: 'Grapeseed',
    text:
      'Grape seed extract may help with poor circulation (chronic venous insufficiency) and high cholesterol. Grape seed extract also reduces swelling caused by injury and helps with eye disease related to diabetes.*',
  },
  {
    image: 'dietary_fiber',
    name: 'Dietary fiber',
    text:
      'In people with high blood sugar, fiber can slow the absorption of sugar and help improve blood sugar levels. A healthy diet that includes insoluble fiber may also reduce the risk of developing type 2 diabetes. Also, fiber helps your digestive system, which helps you shed the unwanted, and in this case, dangerous pounds off your body.*',
  },
  {
    image: 'guggul_gum',
    name: 'Guggul gum',
    text:
      'Guggul contains a mixture of plant compounds, including steroids, essential oils, lignans, flavonoids, carbohydrates, and amino acids – all of which may be responsible for its various health effects. It has been used to promote weight loss, treat hypothyroidism, and manage cholesterol and blood sugar levels.*',
  },
];

interface IProps {
  onClick: () => void;
}

export const PulsioIngredients: React.FC<IProps> = ({ onClick }) => {
  const { isMobile } = useQuery();

  return (
    <Container>
      <FlexWrapper flexDirection="column" alignItems="center">
        <StyledH1
          fontSize="2.625rem"
          mobileFontSize="2.125rem"
          lineHeight="3.375rem"
          mobileLineHeight="2.75rem"
          margin={isMobile ? '2.5rem 0 5rem' : '0 0 5rem'}
        >
          Pulsio has the best{' '}
          <RedHighlight>ingredients for a healthy heart</RedHighlight>
        </StyledH1>
        <StyledGridWrapper columns={isMobile ? 1 : 2} gap="1.875rem">
          {PULSIO_INGREDIENTS.map((item, idx) => (
            <IngredientWrapper key={idx}>
              <IngredientImageWrapper>
                <Image src={item.image} />
              </IngredientImageWrapper>
              <IngredientName>{item.name}</IngredientName>
              <IngredientText>{item.text}</IngredientText>
            </IngredientWrapper>
          ))}
        </StyledGridWrapper>
        <CtaButton
          maxWidth={isMobile ? '100%' : '12rem'}
          margin={isMobile ? '2rem auto' : '2rem auto 0'}
          onClick={onClick}
        >
          GET TREATMENT
        </CtaButton>
      </FlexWrapper>
    </Container>
  );
};

const RedHighlight = styled.span`
  color: ${primary};
`;

const StyledH1 = styled(H1)`
  max-width: 37.125rem;
  text-align: center;
`;

const StyledGridWrapper = styled(GridWrapper)`
  width: 100%;
`;

const IngredientWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IngredientImageWrapper = styled.div`
  width: 100%;
  padding: 1.875rem;
  border: 1px solid ${tints.blue(0.1)};
  border-radius: 10px;
  background: ${white};
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  height: 10.25rem;
  align-items: center;
`;

const IngredientName = styled.div`
  margin-bottom: 0.5rem;
  color: ${text};
  font-size: 1.25rem;
`;

const IngredientText = styled.div`
  color: rgba(23, 22, 22, 0.7);
  font-size: 1rem;
  line-height: 1.688rem;
`;

const CtaButton = styled(PrimaryButton)`
  background: ${blue} !important;
  color: ${white} !important;
  border: none;
`;
